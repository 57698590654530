import useSnackbarStore from '@/store/snackbar';
import { storeToRefs } from 'pinia';

/**
 * useSnackbar:
 * A composable function to show a snackbar message.
 *
 * @returns {Object} { visibility, message, color, list, timer, icon, showSnackbar, clearSnackbar }
 *
 * @example
 * const { visibility, message, color, list, timer, icon, showSnackbar, clearSnackbar } = useSnackbar();
 *
 * showSnackbar({
 *   message: 'This is a snackbar message',
 *   color: 'success',
 *   list: ['Item 1', 'Item 2'],
 *   timer: 5000,
 *   icon: 'mdi-check-circle',
 * });
 */
export default function useSnackbar() {
  const snackbarStore = useSnackbarStore();
  const { showSnackbar, clearSnackbar } = useSnackbarStore();
  const { visibility, message, color, icon, list, timer } = storeToRefs(snackbarStore);

  return {
    visibility,
    message,
    color,
    list,
    timer,
    icon,
    showSnackbar,
    clearSnackbar,
  };
}
